// create a component using react-bootstrap
// call Sections
// the top line should have an InputWrapper to get "number of sections"
// the next line should have a button to "get sections"
// below this create a two coloumn row,
// the left row should call a Section component for each section in props.book.sections
// the right hand side should display the text in props.book.sctions[index].text

import React, {useContext} from "react";
import InputWrapper from "../../components/inputWrapper";
import Section from "./section";
import { Row, Col, Button, Form } from "react-bootstrap";
import { BookContext } from "../../context/bookContext";

export const Sections = (props) => {
    const {
      book,
      setBook,
      GetSections,
      activeContent,
    } = useContext(BookContext);
  console.log("Active Content", activeContent)
  return (
    <div>
      <InputWrapper
        label="Number of Sections"
        type="number"
        value={book?.details?.sections || 4}
        setValue={(value) =>
          setBook((b) => {
            return { ...b, details: { ...b.details, sections: value } };
          })
        }
      />
      <Button onClick={() => GetSections()}>Get Sections</Button>
      <Row>
        <Col style={{ height:"100%", maxHeight: "80vh", overflow: "scroll" }}>
          {props?.book?.sections &&
            props.book.sections.map((section, index) => (
              <Section key={index} section={section} />
            ))}
        </Col>
        <Col>
          <h2>{activeContent.title}</h2>
          <Form.Control
            as="textarea"
            rows={20}
            value={activeContent.description}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Sections;
