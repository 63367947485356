// a react component that displays a list of options, with a radio button. The selected value will by a prop called value.
// when a radio button is selected the setvalue prop will be called with the new value
// the options are listed in the options prop which is an array of strings
// use react-bootstrap components

import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

const Titles = (props) => {
    if (!props.options) {
        return null;
    }
    console.log("PROPS",props)
  return (
    <Row>
      <Col xs={12}>
        <Button onClick={()=>{props.GetTitles()}}>Get Titles</Button>
      </Col>
      <Col xs={12}>
        <Form.Group>
          {props.options.map((option, index) => (
            <Form.Check
              key={index}
              type="radio"
              label={option}
              name="titles"
              id={option}
              checked={props.value === option}
              onChange={() => props.setvalue(option)}
            />
          ))}
        </Form.Group>
      </Col>
    </Row>
  );
}

export default Titles;