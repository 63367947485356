// create a component, Files, using react-bootstrap
// the component will display a two buttons, save and load
// when save is pressed save the props.book to a file using the standard browser savefile
// when load is pressed load a file using the standard browser openfile and call setBook with the file content

import React, { useContext } from 'react';
import { Button, ButtonToolbar , ButtonGroup, InputGroup, Form } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { File, FiletypeDocx, QuestionCircle, Save, Upload } from 'react-bootstrap-icons';
import { BookContext } from '../context/bookContext';

const Files = (props) => {
  const { book, setDetail, GetBookIdea } = useContext(BookContext);
    const save = () => {
      console.log("Save Book", props.book)
        const blob = new Blob([JSON.stringify(props.book)], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${book.details.filename||"bookmaker"}.book.json`);
    }
    const load = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = e => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = e => {
                const text = e.target.result;
                props.setBook(JSON.parse(text));
            };
            reader.readAsText(file);
        }
        input.click();
    }
    return (
      <ButtonToolbar>
        <ButtonGroup>
          <Button onClick={()=>{GetBookIdea()}} variant="outline-primary">
            Get Idea <QuestionCircle style={{ marginLeft: "30px" }} />
          </Button>
          <Button onClick={save}>
            Save <Save style={{ marginLeft: "30px" }} />
          </Button>
          <Button onClick={load}>
            Load <Upload style={{ marginLeft: "30px" }} />
          </Button>
          <Button onClick={load} disabled>
            Export <FiletypeDocx style={{ marginLeft: "30px" }} />
          </Button>
        </ButtonGroup>
        <InputGroup style={{ marginLeft: "55px" }}>
          <Form.Control
            type="text"
            placeholder="File Name"
            value={book.details.filename}
            onChange={(ev) => {
              setDetail("filename", ev.target.value);
            }}
          />
        </InputGroup>
      </ButtonToolbar>
    );
}

export default Files;
