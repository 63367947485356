import OpenAI from "openai-api";

const OPENAI_API_KEY = "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";

const openai = new OpenAI(OPENAI_API_KEY);

const gptResponse = (prompt) => {
  console.log("gptResponse, prompt: ", prompt);
  return openai.complete({
    engine: "text-davinci-003",
    prompt: prompt,
    maxTokens: 3500,
    temperature: 0.5,
    topP: 1,
    presencePenalty: 0,
    frequencyPenalty: 0,
    bestOf: 1,
    n: 1,
    stream: false,
  });
};

const useOpenAI = () => {
  return { gptResponse };
};

export default useOpenAI;
