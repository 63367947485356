// for each chapter in book that matches the prop.sectionTitle
// call the chapter component and pass the chapter data

import React, { useContext } from "react";
import Chapter from "./chapter";
import { BookContext } from "../../context/bookContext";

const Chapters = (props) => {
  const { book } = useContext(BookContext);
  const sectionChapters = book.chapters.filter((chapter) => {
    return chapter.section === props.section;
  });
  let chapters = [];
  if (sectionChapters.length > 0) {
    chapters = sectionChapters[0].chapters;
  }
  return (
    <div>
      {chapters.map((chapter, index) => {
        return <Chapter key={index} chapter={chapter} />;
      })}
    </div>
  );
};

export default Chapters;
