import React, { createContext, useState, useEffect } from "react";
import { useSpinner } from "react-usespinner";
import useOpenAI from "../hooks/useOpenAI";

var template = require("es6-template-string");

export const BookContext = createContext({});

export const BookProvider = ({ children }) => {
  const [activeTitle, setActiveTitle] = useState();
  const [isBusy, setIsBusy] = useState(false);
  const { start, end, busy } = useSpinner();

  const Models = [
    {
      name: "getBookIdea",
      prompt: `Please suggest an idea for a book I could write that would teach someone something, 
      please provide the following additonal data
      subject the subject of the book; 
                    target the reader demographics
                    style being humorous, formal or semi-formal
                    voice: one of 
                            "first-person, use we",
                            "first-person, use I",
                            "second person, use you",
                            "third-person",
                    ideas what are the main topics of the book
        please respond with only the following json format:{"details":{"subject":"","target":"","style":"","voice":"","ideas":"","sections": 3}}`,
    },
    {
      name: "I want to write my own book ...",
      prompts: [
        {
          name: "titles",
          prompt: `I would like to write a \${book.details.style} book about \${book.details.subject}. 
      The target market is \${book.details.target}.  
      Some ideas to be covered in the book are \${book.details.ideas}.  
      Please provide 10 possible titles, please reply in json format with the titles in an array called titles 
      like this { "titles":["title1","title2","title3"]}"}`,
        },
        {
          name: "introduction",
          prompt: `I would like to write a \${book.details.style} book about \${book.details.subject}, 
      the title of the book is \${book.title}.
       The target market is \${book.details.target}. 
       Some ideas to be covered in the book are \${book.details.ideas}. 
       Please write an introduction of 1000 words for the book. Do not add a title.`,
        },
        {
          name: "getSections",
          prompt: `I would like to write a book about \${book.details.subject}, the title of the book is \${book.title}.
          The target market is \${book.details.target}. 
          Some ideas to be covered in the book are \${book.details.ideas}.
          The book is being written in a \${book.details.style} style. 
          Please suggest \${book.details.sections} sections for the book. 
          Please reply in json format with the sections in an array called sections, each section object should contain a title and a description.`,
        },
        {
          name: "getSectionIntro",
          prompt: `I am writing a book about \${book.details.subject}, the title of the book is \${book.title}. 
          The book is written to be used by \${book.details.target}. 
          The book is being written in a \${book.details.style} style.
          I am writing the introduction for the section \${section.title},
          Please write an introduction of 1500 words for the section. Do not add a title.
          Write in \${book.details.voice}.`,
        },
        {
          name: "getSectionChapters",
          prompt: `I am writing a book about \${book.details.subject}, the title of the book is \${book.title}.
          The book is written to be used by \${book.details.target}. 
          The book is being written in a \${book.details.style} style. 
          I am writing the section \${section.title},
          Please suggest \${section.count} chapters for the section. 
          Write in \${book.details.voice}.
          Please reply in json format with the chaptrers in an array called chapters
          like this { "chapters":[{"title":"","description:""}]}`,
        },
        {
          name: "getContent",
          prompt: `I am writing a book about \${book.details.subject}, the title of the book is \${book.title}.
          The book is written to be used by \${book.details.target}. 
          The book is being written in a \${book.details.style} style.
          I am writing the \${content.type} \${content.title},
          Write in \${book.details.voice}.
          Please write the content of 2000 words for the chapter. Do not add a title.`,
        },
      ],
    },

    {
      name: "I want you to act as an author ...",
      prompts: [
        {
          name: "titles",
          prompt: `I want you to act like an author and assist me in writing a book, 
          The subject of the book \${book.details.subject}. 
          The target market for the book are \${book.details.target}. 
          I will be writing from the point of view of the cyclist suggesting ideas to the city. 
          Retain a \${book.details.style}. Write in \${book.details.voice}.
          Please provide 10 possible titles, please reply in json format with the titles in an array called titles
          like this { "titles":["title1","title2","title3"]}"}`,
        },
        {
          name: "introduction",
          prompt: `I want you to act like an author and assist me in writing a book, 
          The subject of the book \${book.details.subject}. 
          The target market for the book are \${book.details.target}. 
          I will be writing from the point of view of the cyclist suggesting ideas to the city. 
          Retain a \${book.details.style}. Write in \${book.details.voice}.
       Please write an introduction of 1500 words for the book. Do not add a title.`,
        },
        {
          name: "getSections",
          prompt: `I want you to act like an author and assist me in writing a book called "\${book.title}". 
          The subject of the book is \${book.details.subject}. 
          The book is written to be used by \${book.details.target}. 
          I'd like to retain \${book.details.style} tone in the book. 
          please suggest \${book.details.sections} main sections for the book, include an introduction and conclusion section. 
          Topics covered should be \${book.details.ideas}. 
          Please reply in json format with the sections in an array called sections
          like this { "sections":[{"title":"","description:""}]}`,
        },
        {
          name: "getSectionIntro",
          prompt: `I want you to act like an author and assist me in writing a book called "\${book.title}". 
          The subject of the book is \${book.details.subject}. 
          The book is written to be used by \${book.details.target}. 
          I'd like to retain \${book.details.style} tone in the book. 
          I am writing the introduction for the section \${section.title},
          Please write an introduction of 1500 words for the section. Do not add a title.
          Write in \${book.details.voice}.`,
        },
        {
          name: "getSectionChapters",
          prompt: `I want you to act like an author and assist me in writing a book called "\${book.title}". 
          The subject of the book is \${book.details.subject}. 
          The book is written to be used by \${book.details.target}. 
          I'd like to retain \${book.details.style} tone in the book. 
          I am writing the section \${section.title},
          Please suggest \${section.count} chapters for the section. 
          Write in \${book.details.voice}.
          Please reply in json format with the chaptrers in an array called chapters
          like this { "chapters":[{"title":"","description:""}]}`,
        },
        {
          name: "getContent",
          prompt: `I want you to act like an author and assist me in writing a book called "\${book.title}". 
          The subject of the book is \${book.details.subject}. 
          The book is written to be used by \${book.details.target}. 
          I'd like to retain \${book.details.style} tone in the book. 
          I am writing the \${content.type} \${content.title},
          Write in \${book.details.voice}.
          Please write the content of 2000 words for the chapter. Do not add a title.`,
        },
      ],
    },
  ];

  const [book, setBook] = useState({
    title: "",
    introduction: "Not Loaded",
    titles: [],
    sections: [],
    chapters: [],
    content: [],
    details: {
      subject: "Enter a subject for the book",
      target: "Enter a target market",
      style: "semi-formal",
      voice: "third person",
      ideas: "Enter a few ideas for the book",
      sections: 4,
    },
  });

  const Model = Models.find(
    (i) => i.name === (book.details.model || "I want to write my own book ...")
  );

  const activeContents = book.content.filter((c) => c.title === activeTitle);
  const activeContent =
    activeContents.length > 0
      ? activeContents[0]
      : { title: activeTitle, description: "" };

  const { gptResponse } = useOpenAI();

  const getGPTResponse = (prompt) => {
    start("GPT");
    setIsBusy(true)
    return gptResponse(prompt).then((response) => {
      end("GPT");
      return response;
    }).finally(()=>setIsBusy(false));
  };

  const setDetail = (name, value) => {
    let detail = book.details;
    detail[name] = value;
    setBook((p) => {
      return { ...p, details: detail };
    });
  };
  const setSubject = (subject) => {
    setBook((p) => {
      return { ...p, details: { ...p.details, subject: subject } };
    });
  };
  const setTarget = (target) => {
    setBook((p) => {
      return { ...p, details: { ...p.details, target: target } };
    });
  };
  const setStyle = (style) => {
    setBook((p) => {
      return { ...p, details: { ...p.details, style: style } };
    });
  };
  const setVoice = (value) => {
    setBook((p) => {
      return { ...p, details: { ...p.details, voice: value } };
    });
  };
  const setIdeas = (ideas) => {
    setBook((p) => {
      return { ...p, details: { ...p.details, ideas: ideas } };
    });
  };

  const setTitle = (title) => {
    setBook((p) => {
      return { ...p, title: title };
    });
  };
  const setIntroduction = (intro) => {
    setBook((p) => {
      return { ...p, introduction: intro };
    });
  };
  const setSectionDetail = (sectionTitle, name, value) => {
    let section = book.sections.find((s) => s.title === sectionTitle);
    section[name] = value;
    const sections = book.sections;
    setBook((p) => {
      return { ...p, sections: sections };
    });
  };
  const setActiveContent = (title) => {
    setActiveTitle(title);
  };
  const hasContent = (title) => {
    return book.content.filter((c) => c.title === title).length > 0;
  };

    const GetBookIdea = () => {
      const prompt = template(Models[0].prompt);
      console.log("GetBookIdea prompt", prompt)
      getGPTResponse(prompt).then((response) => {
        console.log("GetBookIdea Response", response);
        const details = JSON.parse(response.data.choices[0].text).details;
        setBook((p) => {
          return { ...p, details: details };
        });
      });
    };

  const GetTitles = () => {
    const prompt = template(Model.prompts[0].prompt, { book: book });
    getGPTResponse(prompt).then((response) => {
      const titles = JSON.parse(response.data.choices[0].text).titles;
      setBook((p) => {
        return { ...p, titles: titles };
      });
    });
  };

  const GetIntroduction = () => {
    console.log("MakeBook-GetIntroduction", book.details.subject);
    getGPTResponse(template(Model.prompts[1].prompt, { book: book })).then(
      (response) => {
        const intro = response.data.choices[0].text;
        setBook((p) => {
          return { ...p, introduction: intro };
        });
      }
    );
  };

  const GetSections = (section) => {
    const prompt = Model.prompts.find((a) => a.name === "getSections").prompt;
    getGPTResponse(template(prompt, { book: book, section: section })).then(
      (response) => {
        const sections = JSON.parse(response.data.choices[0].text).sections;
        setBook((p) => {
          return { ...p, sections: sections };
        });
      }
    );
  };

  const GetChapters = (section) => {
    const prompt = Model.prompts.find((a) => a.name === "getSectionChapters").prompt;
    const params = { book: book, section: section };
    getGPTResponse(template(prompt, params)).then((response) => {
      const info = JSON.parse(response.data.choices[0].text).chapters;
      const chapters = book.chapters.filter((c) => c.section !== section.title);
      const chapterdata = { section: section.title, chapters: info };
      chapters.push(chapterdata);
      setBook((p) => {
        return { ...p, chapters: chapters };
      });
    });
  };

  // const GetSections = () => {
  //   console.log("MakeBook-getSections", book);
  //   const prompt = template(Model.prompts.find(a => a.name === "getSections").prompt);
  //   gptResponse(
  //     `I am writing a book about ${book.details.subject}, the title of the book is ${book.title}.
  //      The target market is ${book.details.target}.
  //      Some ideas to be covered in the book are ${book.details.ideas}.
  //      The book is being written in a ${book.details.style} style.
  //      Please suggest ${book.details.sections} sections for the book.
  //      Please reply in json format with the sections in an array called sections, each section object should contain a title and a description.`
  //   ).then((response) => {
  //     console.log("GetSections response: ", response.data.choices[0].text);
  //     const sections = JSON.parse(response.data.choices[0].text).sections;
  //     setBook((p) => {
  //       return { ...p, sections: sections };
  //     });
  //   });
  // };
  // const GetChapters = (section) => {
  //   console.log("MakeBook-getChapters", book);
  //   gptResponse(
  //     `I am writing a book about ${
  //       book.details.subject
  //     }, the title of the book is ${book.title}.
  //      The target market is ${
  //        book.details.target
  //      }. The book is being written in a ${book.details.style} style.
  //      I am now working on the ${section.title} section of the book.
  //      Please suggest ${section.count || 5} chapters for the Section.
  //      Please reply in json format with the chapters in an array called chapters, each chapter object should contain a title and a description.`
  //   ).then((response) => {
  //     console.log("GetChapters response: ", response.data.choices[0].text);
  //     const info = JSON.parse(response.data.choices[0].text).chapters;
  //     const chapters = book.chapters.filter((c) => c.section !== section.title);
  //     const chapterdata = { section: section.title, chapters: info };
  //     chapters.push(chapterdata);
  //     setBook((p) => {
  //       return { ...p, chapters: chapters };
  //     });
  //   });
  // };

  const GetContent = (contentType, contentTitle, contentIdeas) => {
    const prompt = Model.prompts.find((a) => a.name === "getContent").prompt;
    const params = {
      book: book,
      content: {
        type: contentType,
        title: contentTitle,
        ideas: contentIdeas,
      },
    };
    getGPTResponse(template(prompt, params)).then((response) => {
      const info = response.data.choices[0].text;
      const content = book.content.filter((c) => c.title !== contentTitle);
      const contentItem = {
        title: contentTitle,
        description: info,
      };
      content.push(contentItem);
      setBook((p) => {
        return { ...p, content: content };
      });
    });
  };
  // const GetContent = (contentType, contentTitle, contentIdeas) => {
  //   console.log("MakeBook-getChapters", book);
  //   setActiveContent(contentTitle);
  //   gptResponse(
  //     `I am writing a book about ${
  //       book.details.subject
  //     }, the title of the book is ${book.title}.
  //      The target market is ${
  //        book.details.target
  //      }. The book is being written in a ${book.details.style} style.
  //      I am now working on the ${contentType}, ${contentTitle} of the book.
  //      ${
  //        contentIdeas
  //          ? `Some ideas to include in the content are ${contentIdeas}.`
  //          : ``
  //      }
  //      Please write 1500 words of content for ${contentTitle}.`
  //   ).then((response) => {
  //     console.log("GetContent response: ", response.data.choices[0].text);
  //     const info = response.data.choices[0].text;
  //     const content = book.content.filter((c) => c.title !== contentTitle);
  //     const contentItem = {
  //       title: contentTitle,
  //       description: info,
  //     };
  //     content.push(contentItem);
  //     setBook((p) => {
  //       return { ...p, content: content };
  //     });
  //   });
  // };

  return (
    <BookContext.Provider
      value={{
        setDetail,
        setSectionDetail,
        setSubject,
        setTarget,
        setStyle,
        setVoice,
        setIdeas,
        book,
        setBook,
        GetTitles,
        GetIntroduction,
        setTitle,
        setIntroduction,
        GetSections,
        GetChapters,
        GetContent,
        GetBookIdea,

        setActiveTitle,
        activeContent,
        setActiveContent,
        hasContent,
        busy, isBusy
      }}
    >
      {children}
    </BookContext.Provider>
  );
};
