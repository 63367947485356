// create a header component, that displays an h1 title, then 2 inputWrappers and a selectWrapper

import React, { useContext } from 'react';

import InputWrapper from '../components/inputWrapper'
import SelectWrapper from "../components/selectWrapper";
import { BookContext } from '../context/bookContext';
import Files from '../components/files';
import { Modal, Spinner } from 'react-bootstrap';

const Header = () => {
    // get values from context
    const { book, setBook, setSubject, setTarget, setStyle, setVoice, setIdeas, setDetail, busy } =
      useContext(BookContext);

      return (
        <div className="header">
          {busy() && (
            <Modal shot={true} centered={true} backdrop="static">
              <Modal.Body>
                <Spinner animation="border" role="status" />
              </Modal.Body>
            </Modal>
          )}
          <h1>Book Maker: {book.title}</h1>
          <Files book={book} setBook={setBook} />

          <SelectWrapper
            label="Writing Assistant Model"
            value={book.details.style}
            setValue={(val) => { setDetail("model", val)}}
            options={["I want to write my own book ...", "I want you to act as an author ..."]}
          />
          <InputWrapper
            label="What is the subject of the book"
            value={book.details.subject}
            setValue={setSubject}
          />
          <InputWrapper
            label="Who are the target market"
            value={book.details.target}
            setValue={setTarget}
          />
          <SelectWrapper
            label="What voice will the book be written in"
            value={book.details.voice || "third-person"}
            setValue={setVoice}
            options={[
              "first-person, use we",
              "first-person, use I",
              "second person, use you",
              "third-person",
            ]}
          />
          <SelectWrapper
            label="What style should the book use"
            value={book.details.style}
            setValue={setStyle}
            options={["humerous", "semi-formal", "formal"]}
          />
          <InputWrapper
            label="Give some ideas as guidance to what should be covered in the book"
            value={book.details.ideas}
            setValue={setIdeas}
          />
        </div>
      );
}

export default Header;