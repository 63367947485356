// create a help page using react-bootstrap offcanvas
// display a fixed round button with question mark in top right corner
// when clicked the overlay should display a help page, sliding in from the right
// the help page should have a close button (X) in the top right corner
// the help page should have a title
// the help page should have a list of help items
// when a help item is clicked the bottom portion of the overlay should display the selected help text
// the help text should be in a scrollable area

import React, { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";

const Help = () => {
    const [show, setShow] = useState(false);
    const [item, setItem] = useState();
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
      <>
        <Button
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            zIndex: "1000",
          }}
          variant="primary"
          onClick={handleShow}
        >
          ?
        </Button>

        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Help</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setItem("Get a random idea")}
                >
                  Get a random idea
                </span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>Book Meta data</span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>Getting a title</span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>
                  Getting an introduction
                </span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>
                  Getting a list of sections
                </span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>
                  Getting an introduction per section
                </span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>
                  Getting chapters per section
                </span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>
                  Getting an introduction
                </span>
              </li>
              <li>
                <span style={{ cursor: "pointer" }}>Saving and Loading</span>
              </li>
              <li>
                <X style={{ color: "red" }} /> Exporting for KDP
              </li>
            </ul>
            <p>
              <h3>{item}</h3>
              <p>Click on the get Idea button to get the detaisl for a random idea. The text inputs at the top of the screen are the Meta-Data that will be used by AI to create a book for you.</p>
              <p>You can enter your own data in these boxes to get a book based on your ideas</p>

            </p>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
    }

export default Help;