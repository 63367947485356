import logo from './logo.svg';
import './App.scss';
import { BookProvider } from './context/bookContext';
import Header from './parts/header';

// import "bootstrap/dist/css/bootstrap.min.css";
import Content from './parts/content';
import { Container } from 'react-bootstrap';
import Help from './parts/help';

function App() {
  return (
    <BookProvider>
      <Help />
      <Container>
        <Header />
        <Content />
      </Container>
    </BookProvider>
  );
}

export default App;
