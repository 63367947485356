// using react-bootstrap create a row and col that displays a label, an inputbox. 
// The label comes from a prop, as does value and setvalue.
// The inputbox is a react-bootstrap form-control
// The inputbox is a controlled component, so it's value is set by the value prop
// and the setvalue function is called when the inputbox changes

import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const InputWrapper = ({ label, value, setValue }) => {
    return (
        <Row>
            <Col xs={4}>
                <Form.Label>{label}</Form.Label>
            </Col>
            <Col xs={8}>
                <Form.Control
                    type="text"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </Col>
        </Row>
    );
}

export default InputWrapper;