// a react component that displays a list of options, with a radio button. The selected value will by a prop called value.
// when a radio button is selected the setvalue prop will be called with the new value
// the options are listed in the options prop which is an array of strings
// use react-bootstrap components

import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';

const Introduction = (props) => {
  return (
    <Row>
      <Col xs={12}>
        <Button onClick={()=>{props.GetIntroduction()}}>Get Introduction</Button>
      </Col>
      <Col xs={12}>
        <Form.Group>
          <Form.Control as="textarea" rows="15" value={props.value} onChange={(e) => props.setIntroducion(e.target.value) } />
        </Form.Group>
      </Col>
    </Row>
  );
}

export default Introduction;