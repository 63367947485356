// create a component using react-bootstrap
// the props will include a chapter object
// the component should display the chapter title
// when a chapter is clicked the component should call props.setsection with the chapter object

import React, { useContext } from "react";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { CardText, Pencil, XCircleFill } from "react-bootstrap-icons";
import { BookContext } from "../../context/bookContext";

const Chapter = (props) => {
  const { setActiveTitle, GetContent, hasContent } = useContext(BookContext);
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <span onClick={() => setActiveTitle(props.chapter.title)}>
            {props.chapter.title}
          </span>
          {!hasContent(props.chapter.title) && (
            <XCircleFill style={{ float: "right", color: "red" }} />
          )}
        </Card.Title>
        <div>{props.chapter.description}</div>
        <ButtonGroup>
          <Button style={{ paddingRight: "15px", fontSize: "14px" }}>
            Edit <Pencil />
          </Button>
          <Button style={{ paddingRight: "15px", fontSize: "14px" }}>
            Get Content <CardText
              onClick={() => GetContent("chapter", props.chapter.title)}
            />
          </Button>
        </ButtonGroup>
      </Card.Header>
    </Card>
  );
};

export default Chapter;
