// create a component using react-bootstrap
// the props will include a section object
// the component should display the section title as a collapsable
// when option the section should display a list of chapters using a Chapter component
// each chapter should display the chapter.title
// when a section is opened the component should call props.setsection with the section object

import React, { useState, useContext } from 'react';
import { Accordion, Card, Button, ButtonGroup } from 'react-bootstrap';
import { Book, BookFill, CardText, Pencil, PlusCircleFill, DashCircleFill, XCircleFill } from 'react-bootstrap-icons';
import Chapter from './chapter';
import { BookContext } from '../../context/bookContext';
import Chapters from './chapters';

const Section = (props) => {
    const { book, setBook, GetChapters, setActiveContent, GetContent, hasContent, setSectionDetail } =
      useContext(BookContext);
    const getChaptersForSection = () => {
      console.log("Get Chapters for section: " + props.section.title)
      GetChapters(props.section);
    }
    const gotContent = hasContent(props.section.title);
    const [isClosed, setIsClosed] = useState(props.section.closed || false);
    
    return (
      <Card>
        <Card.Header>
          <Card.Title>
            {isClosed ? (
              <PlusCircleFill
                style={{ marginRight: "15px", color: "#0d6efd" }}
                onClick={() => {
                  props.section.closed = false;
                  setIsClosed(false);
                  console.log("section", props.section);
                }}
              />
            ) : (
              <DashCircleFill
                style={{ marginRight: "15px", color: "#0d6efd" }}
                onClick={() => {
                  props.section.closed = true;
                  setIsClosed(true);
                  console.log("section", props.section);
                }}
              />
            )}
            <span
              onClick={() => {
                setActiveContent(props.section.title);
              }}
            >
              {props.section.title}
            </span>
            {!gotContent && (
              <XCircleFill style={{ float: "right", color: "red" }} />
            )}
          </Card.Title>
          {!isClosed && (
            <>
              <div>{props.section.description}</div>
              <ButtonGroup>
                <Button style={{ paddingRight: "15px", fontSize: "14px" }}>
                  Edit <Pencil />
                </Button>
                <Button
                  style={{ paddingRight: "15px", fontSize: "14px" }}
                  onClick={() => GetContent("section", props.section.title)}
                >
                  Get Intro <CardText />
                </Button>
                <Button
                  style={{ paddingRight: "15px", fontSize: "14px" }}
                  onClick={() => getChaptersForSection()}
                >
                  get Chapters{" "}
                  <input
                    style={{ width: "25px" }}
                    value={props.section.count || 5}
                    onChange={(ev) => {
                      setSectionDetail(
                        props.section.title,
                        "count",
                        ev.target.value
                      );
                    }}
                  ></input>{" "}
                  <Book />
                </Button>
              </ButtonGroup>
            </>
          )}
        </Card.Header>
        <Card.Body>
          <PlusCircleFill /><Chapters section={props.section.title} />
        </Card.Body>
      </Card>
    );
    }

export default Section;