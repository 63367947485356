// using react-bootstrap create a row and col that displays a label, a select list.
// The label comes from a prop, as does options, value and setvalue.
// The inputbox is a react-bootstrap form-control
// The inputbox is a controlled component, so it's value is set by the value prop
// and the setvalue function is called when the inputbox changes

import React from "react";
import { Row, Col, Form } from "react-bootstrap";

const SelectWrapper = ({ label, options, value, setValue }) => {
  return (
    <Row>
      <Col xs={4}>
        <Form.Label>{label}</Form.Label>
      </Col>
      <Col xs={8}>
        <Form.Control
          as="select"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Row>
  );
};

export default SelectWrapper;
