// a react functional component that uses react-bootstrap tabs to display different pages
// the first page is "titles"
// the second page is "introduction"
// the third page is "sections"
// the first page should show the "Titles" component
// use arrow function syntax
// use the book value from BookContext

import React, { useContext } from "react";
import { Tabs, Tab, Spinner } from "react-bootstrap";

import Titles from "./titles";
import { BookContext } from "../context/bookContext";
import Introduction from "./introduction";
import Sections from "./sections/sections";

const Content = () => {
  const {
    book,
    GetTitles,
    setTitle,
    GetIntroduction,
    SetIntroduction,
    isBusy,
  } = useContext(BookContext);

  if (isBusy) {
    return <Spinner animation="border" />;
  }

  return (
    <Tabs defaultActiveKey="titles" id="uncontrolled-tab-example">
      <Tab eventKey="titles" title="Titles">
        <Titles
          options={book.titles}
          GetTitles={GetTitles}
          value={book.title}
          setvalue={setTitle}
        />
      </Tab>
      <Tab eventKey="introduction" title="Introduction">
        <Introduction
          GetIntroduction={GetIntroduction}
          SetIntoduction={SetIntroduction}
          value={book.introduction}
        />
      </Tab>
      <Tab eventKey="sections" title="Sections">
        <Sections book={book} />
      </Tab>
    </Tabs>
  );
};

export default Content;
